import { createRouter, createWebHistory } from "vue-router";
import Home from '@/views/Home.vue'
import Operator from '@/views/Operator.vue'
import Train from '@/views/Train.vue'
import Route from '@/views/Route.vue'
import Tracks from '@/views/Tracks.vue'
import Status from '@/views/Status.vue'
import Statistik from '@/views/Statistik.vue'
import Description from '@/views/Description.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/operator/:name/:operatorID/:date',
        name: 'operator',
        component: Operator,
        props: true
    },
    {
        path: '/train',
        name: 'train',
        component: Train
    },
    {
        path: '/route',
        name: 'route',
        component: Route
    },
    {
        path: '/tracks',
        name: 'tracks',
        component: Tracks
    },
    {
        path: '/punktlighet/:trainNumber',
        name: 'trainWithNumber',
        component: Train,
        props: true
    },
    {
        path: '/train/:trainNumber/:stationFrom/:stationTo',
        name: 'trainWithStations',
        component: Train,
        props: true
    },
    {
        path: '/route/:date/:fromStation/:toStation',
        name: 'routeWithQuery',
        component: Route,
        props: true
    },
    {
        path: '/punktlighet/:fromStation/:toStation',
        name: 'routeWithoutDate',
        component: Route,
        props: true
    },
    {
        path: '/track/:tagNr/:station',
        name: 'trackWithQuery',
        component: Tracks,
        props: true
    },
    {
        path: '/beskrivning/:orsak',
        name: 'description',
        component: Description,
        props: true
    },
    {
        path: '/position',
        name: 'position',
        component: Status
    },
    {
        path: '/statistik',
        name: 'statistik',
        component: Statistik
    }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <div class="main">
    <!-- <vue-headful :title="title" /> -->
    <navbar />
    <div class="container">
      <div class="train-status p-3">
        <p class="breadcrumbs no-mobile">
          <span
            ><router-link :to="{ name: 'home' }">Start</router-link> /
            <router-link :to="{ name: 'statistik' }">Statistik</router-link>
            /</span
          >
          Tågnummer
        </p>
        <div class="input-fields">
          <div class="mb-3">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Tågnummer"
                v-model="trainNr"
                @keyup.13.native="update"
              />
              <div class="datepicker">
                <datepicker
                  :locale="lang"
                  class="form-control"
                  :style="style"
                  v-model="datum"
                  placeholder="Välj datum"
                  @update:modelValue="selectedDate"
                ></datepicker>
              </div>
              <button
                type="button"
                class="btn btn-info"
                @click="fetchTrainPosition"
              >
                Sök
              </button>
            </div>
          </div>
        </div>
        <section class="train-position px-3">
          <pre>{{ positions }}</pre>
          <div class="table-responsive" v-if="positions.length > 0">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" colspan="3" class="head">
                    {{ titleTrain }} {{ operator }}
                  </th>
                  <th
                    scope="col"
                    colspan="2"
                    class="head centered"
                    style="
                      border-left: 1px solid lightgrey;
                      border-right: 1px solid lightgrey;
                      text-align: center;
                    "
                  >
                    Planerad
                  </th>
                  <th
                    scope="col"
                    colspan="2"
                    class="head centered"
                    style="
                      border-left: 1px solid lightgrey;
                      border-right: 1px solid lightgrey;
                      text-align: center;
                    "
                  >
                    Diff
                  </th>
                  <th
                    scope="col"
                    colspan="2"
                    class="head centered"
                    style="
                      border-left: 1px solid lightgrey;
                      border-right: 1px solid lightgrey;
                      text-align: center;
                    "
                  >
                    Verklig
                  </th>
                  <th scope="col"></th>
                </tr>
                <tr>
                  <th scope="col">Km</th>
                  <th scope="col">Plats</th>
                  <th scope="col" class="head spar">Spår</th>
                  <th scope="col" style="border-left: 1px solid lightgray">
                    Ankomst
                  </th>
                  <th
                    scope="col"
                    class="head"
                    style="border-right: 1px solid lightgray"
                  >
                    Avgång
                  </th>
                  <th scope="col" style="text-align: center">Ankomst</th>
                  <th
                    scope="col"
                    class="head"
                    style="
                      text-align: center;
                      border-right: 1px solid lightgray;
                    "
                  >
                    Avgång
                  </th>
                  <th scope="col">Ankomst</th>
                  <th
                    scope="col"
                    class="head"
                    style="border-right: 1px solid lightgray"
                  >
                    Avgång
                  </th>
                  <th scope="col">Orsak</th>
                </tr>
              </thead>
              <tbody v-if="positions.length > 0">
                <tr v-for="(pos, index) in positions" :key="index">
                  <td class="km">{{ pos.km }}</td>
                  <td class="station">
                    {{ pos.tpl }}
                  </td>
                  <router-link
                    :to="{
                      name: 'trackWithQuery',
                      params: { station: pos.tpl, tagNr: trainNr },
                    }"
                  >
                    <td
                      class="spar"
                      v-html="
                        pos.spAvg === pos.spAnk
                          ? pos.spAvg
                          : pos.spAnk && pos.spAvg
                          ? '<strong>' +
                            'Ankomst' +
                            ':</strong> ' +
                            pos.spAnk +
                            '<br><strong>' +
                            'Avgång' +
                            '</strong>: ' +
                            pos.spAvg
                          : pos.spAvg
                          ? pos.spAvg
                          : pos.spAnk
                      "
                    ></td>
                  </router-link>
                  <td class="tider">{{ pos.anktid ? pos.anktid : "" }}</td>
                  <td class="tider">
                    {{
                      pos.annAvg && pos.avgtid
                        ? pos.annAvg + " (" + pos.avgtid + ")"
                        : pos.avgtid
                        ? pos.avgtid
                        : ""
                    }}
                  </td>
                  <td
                    style="text-align: center"
                    :class="{
                      sen: pos.statusAnk < 0,
                      tidig: pos.statusAnk >= 0,
                    }"
                  >
                    {{ (pos.statusAnk &lt; 0) ? Math.abs(pos.statusAnk) : pos.statusAnk }}
                  </td>
                  <td
                    style="text-align: center"
                    :class="{
                      sen: pos.statusAvg < 0,
                      tidig: pos.statusAvg >= 0,
                    }"
                  >
                    {{ (pos.statusAvg &lt; 0) ? Math.abs(pos.statusAvg) : pos.statusAvg}}
                  </td>
                  <td class="tider">
                    <strong>{{
                      pos.verkAnk
                        ? pos.verkAnk
                        : pos.bertidAnk
                        ? "Ber." + pos.bertidAnk
                        : ""
                    }}</strong>
                  </td>
                  <td class="tider">
                    <strong>{{
                      pos.verkAvg
                        ? pos.verkAvg
                        : pos.bertidAvg
                        ? "Ber." + pos.bertidAvg
                        : ""
                    }}</strong>
                  </td>
                  <td>
                    {{
                      pos.beskrivning && pos.beskrivning.length
                        ? pos.beskrivning
                        : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="alert alert-secondary"
            role="alert"
            v-if="positions.length == 0 && !loading"
          >
            Hittar inget tåget för valt datum
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Navbar from "@/components/Navbar";
import Datepicker from "vue3-datepicker";
import { sv } from "date-fns/locale";
import { mapState } from "vuex";

export default {
  name: "status",
  data() {
    return {
      lang: sv,
      selected: null,
      trainNr: "",
      speed: "",
      titleTrain: "",
      operator: "",
      updated: "",
      positions: [],
      datum: moment().toDate(),
      tforInterval: null,
      error: false,
      loading: false,
      size: "18px",
      title: "Tågstatus",
      style: {
        borderRadius: 0,
        height: "45px",
        minWidth: "250px",
      },
    };
  },

  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },

  components: {
    Navbar,
    Datepicker,
  },

  created() {
    if (this.$route.query.tagNr) {
      this.trainNr = this.$route.query.tagNr;
    }
    if (this.$route.query.datum) {
      this.datum = moment(this.$route.query.datum).toDate();
    }
  },

  mounted() {
    this.fetchTrainPosition();
    this.fetchTrainStatus();
  },

  methods: {
    fetchTrainPosition() {
      if (this.trainNr) {
        this.loading = true;
        this.updateTitle();
        this.$axios
          .get(
            this.baseUrl +
              "api/tfor.php?tagNr=" +
              this.trainNr +
              "&datum=" +
              moment(this.datum).format("YYYY-MM-DD")
          )
          .then((res) => {
            if (res.status >= 200 && res.status < 300) {
              this.loading = false;
              this.titleTrain = res.data.tagNr;
              this.operator = res.data.operator;
              this.updated = res.data.ts;
              this.positions = res.data.result;
              this.speed = res.data.speed;
            }
          })
          .catch(() => {
            this.positions = [];
            this.loading = false;
          });
        console.lo;
      }
    },

    updateTitle() {
      this.title = "Status tåg " + this.trainNr;
    },

    selectedDate(date) {
      this.datum = date;
      this.fetchTrainPosition();
    },

    fetchTrainStatus() {
      this.loading = false;
      setTimeout(() => {
        this.loading = true;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.train-status {
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 10px;

  .breadcrumbs {
    font-weight: 600 !important;
    text-decoration: none;
    font-size: 0.95rem;
    padding: 0 15px;

    a {
      color: #3498db;
    }
  }

  .input-fields {
    border-radius: 4px;
    .input-group {
      input {
        height: 45px;
        // width: 250px;
        border-radius: 0;
        text-align: left;
        padding-left: 15px;
        font-size: 17px;
        margin: 0;
        border: 1px solid #ddd;
      }
      input.to {
        border-right: 0;
      }
      .btn-info {
        width: 150px;
        border-radius: 0;
        font-size: 18px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-color: transparent;
      }
    }
  }
}

.sen {
  color: #e74c3c;
}
.tidig {
  color: #27ae60;
}

.container-fluid {
  padding-bottom: 150px;
  .lastupdate {
    margin: 0;
    font-size: 13px;
    margin-top: 15px;
    padding-left: 5px;
    span {
      font-weight: 600;
      font-size: 12px;
    }
  }
  .train-position {
    background: #fff;
    padding: 15px 15px 1px;
    margin-top: 15px;
    .table {
      thead {
        tr th {
          font-weight: 600;
        }
        .head {
          font-weight: 600;
          border-right-style: solid;
          border-right-width: 1px;
          border-right-color: #dee2e6;
        }
        .head.centered {
          text-align: center;
        }
        th {
          border-bottom: 0;
        }
      }
      tbody tr {
        .station {
          a {
            font-weight: 600;
            color: #148f77;
            &:hover {
              color: #1abc9c;
            }
          }
        }
      }
      .sen,
      .tidig {
        font-weight: 600;
      }
      .sen {
        color: #e74c3c;
      }
      .tidig {
        color: #27ae60;
      }
    }
  }

  @media (max-width: 575.98px) {
    padding: 0;
    .train-position {
      padding: 0;
    }
    .box {
      height: 190px;
    }
    .select {
      margin-top: 15px;
      float: none;
      min-width: 100%;
      select {
        min-width: 100%;
      }
    }
    .table {
      thead {
        th {
          font-size: 0.5rem;
          // font-weight: 600;
          padding: 10px 2px;
        }
        .spar {
          text-align: center;
        }
      }
      tbody {
        tr > td {
          font-size: 0.8rem;
          padding: 5px 0;
        }
        tr > th {
          padding: 5px 1px;
        }
        td.km {
          padding: 0 5px;
        }
        .station,
        .tider {
          font-size: 0.55rem;
        }
        .spar {
          text-align: center;
          min-width: 55px;
          strong {
            font-size: 0.5rem;
          }
          span {
            font-size: 0.55rem;
          }
        }
      }
    }
    .ti .container-fluid {
      .table {
        tr th {
          font-size: 8px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 1199.98px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>

<template>
  <div class="train-route">
    <p class="h4">Punktlighet vid slutstation</p>
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <template v-for="(field, index) in fields">
              <th scope="col" @click="sortColumn(field.key)">
                <div class="header">
                  <span class="name">{{ field.label }}</span>
                  <span class="carets">
                    <i
                      class="bi bi-caret-up-fill"
                      :class="{ active: column === field.key && ascending }"
                    ></i>
                    <i
                      class="bi bi-caret-down-fill"
                      :class="{ active: column === field.key && !ascending }"
                    ></i>
                  </span>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(train, index) in trainlist" :key="index">
            <td>
              <router-link
                v-if="train.operatorID > 0"
                :to="{
                  name: 'operator',
                  params: {
                    name: train.operator,
                    operatorID: train.operatorID,
                    date: getCurrentDate(),
                  },
                }"
                >{{ train.operator }}</router-link
              >
              <span v-else>{{ train.operator }}</span>
            </td>
            <td>{{ train.koncept }}</td>
            <td>{{ train.avgtid }}</td>
            <td>{{ train.anktid }}</td>
            <td>
              <router-link
                :to="{
                  name: 'position',
                  query: { tagNr: train.tagNr, datum: getCurrentDate() },
                }"
                >{{ train.tagNr }}</router-link
              >
            </td>
            <td>
              <span class="status">
                <span
                  :class="{
                    red: train.rating < 50,
                    orange: train.rating >= 50 && train.rating < 75,
                    yellow: train.rating >= 75 && train.rating < 90,
                    green: train.rating >= 90,
                  }"
                  >&nbsp;</span
                >
                <span class="percent">{{ train.rating }}%</span>
              </span>
            </td>
            <td>{{ train.realavg }}</td>
            <td>{{ train.realank }}</td>
            <td>{{ train.diff }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["trainlist", "datum"],
  data() {
    return {
      date: "",
      fields: [
        { key: "operator", label: "Operatör", sortable: true },
        { key: "koncept", label: "Typ", sortable: true },
        {
          key: "avgtid",
          label: "Plan. avg",
          sortable: true,
        },
        {
          key: "anktid",
          label: "Plan. ank",
          sortable: true,
        },
        { key: "tagNr", label: "Tågnr", sortable: true },
        { key: "rating", label: "Punktlighet", sortable: true },
        {
          key: "realavg",
          label: "Avg",
          sortable: true,
        },
        {
          key: "realank",
          label: "Ank",
          sortable: true,
        },
        {
          key: "diff",
          label: "Restid",
          sortable: true,
        },
      ],
      ascending: false,
      column: null,
    };
  },
  methods: {
    getCurrentDate() {
      return moment(this.datum).format("YYYY-MM-DD");
    },

    sortColumn(col) {
      if (this.column === col) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.column = col;
      }

      let ascending = this.ascending;

      this.trainlist.sort((a, b) => {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1;
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.train-route {
  background: #fff;
  padding: 15px;
  .h4 {
    font-size: 18px;
    margin-top: 20px;
    font-weight: 600;
  }
  .carets {
    display: flex;
    flex-direction: column;
    .bi {
      font-size: 13px;
      color: #b2babb;
    }
    .bi-caret-down-fill {
      margin-top: -10px;
    }
    .bi.active {
      color: #424949;
    }
  }
  table {
    margin-top: 15px;
    th,
    td {
      padding: 12px;
    }
    thead {
      tr {
        border-top: 1px solid #ddd;
      }
      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &:hover {
        cursor: pointer;
      }
    }
    a {
      color: #3498db;
      font-weight: 600;
      text-decoration: none;
      font-size: 0.925rem;
      &:hover {
        color: #464445cf;
      }
    }
    .status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .red,
      .orange,
      .yellow,
      .green {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .percent {
        font-weight: 600;
        font-size: 14px;
      }
      .green {
        background: #27ae60;
      }
      .yellow {
        background: #f1c40f;
      }
      .orange {
        background: #e67e22;
      }
      .red {
        background: #e74c3c;
      }
    }
  }

  @media (max-width: 575.98px) {
    .table {
      .status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .red,
        .orange,
        .yellow,
        .green {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
        padding: 5px;
      }
      tbody {
        th {
          font-size: 12px;
        }
        .status .percent {
          font-weight: 600;
        }
        tr > td {
          padding: 3px;
        }
        tr > th {
          padding: 3px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .table {
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
      }
      tbody {
        th {
          font-size: 12px;
        }
        .status .percent {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 1199.98px) {
    .h4 {
      font-size: 17px;
    }
    .table {
      th {
        font-size: 14px;
      }
    }
  }
}
</style>

<template>
  <div class="main">
    <!-- <vue-headful :title="title" /> -->
    <navbar />
    <div class="container">
      <div class="train p-3">
        <p class="breadcrumbs no-mobile">
          <span
            ><router-link :to="{ name: 'home' }">Start</router-link> /
            <router-link :to="{ name: 'statistik' }">Statistik</router-link>
            /</span
          >
          Tågnummer
        </p>
        <p class="info-text m-3">
          Om du vill se hela tågets sträcka räcker det att ange tågnummer och
          trycka Sök. Är du bara intresserad av en delsträcka, fyll i Från och
          Till också.
        </p>
        <div class="input-fields">
          <div class="mb-3">
            <div class="input-group mb-3">
              <input
                type="number"
                class="form-control"
                placeholder="Tågnummer"
                v-model="tagNr"
                @keyup.13.native="update"
              />
              <input
                type="text"
                class="form-control"
                placeholder="Från"
                v-model="from"
                @keyup.13.native="update"
              />
              <input
                type="text"
                class="form-control"
                placeholder="Till"
                v-model="to"
                @keyup.13.native="update"
              />
              <button type="button" class="btn btn-info" @click="update">
                Sök
              </button>
            </div>
          </div>
        </div>
        <div class="delay">
          <div class="d-flex flex-row mb-2">
            <div class="p-2 fw-bold">Förseningsminuter:</div>
            <div class="p-2">
              <input
                type="number"
                class="form-control"
                placeholder="Förseningsminuter"
                v-model="delayLimit"
                @change="updateStats"
              />
            </div>
          </div>
          <div class="d-flex flex-row mb-2">
            <div class="p-2 fw-bold">Punktlighet:</div>
            <div class="p-2">{{ this.rating ? this.rating : 0 }}%</div>
          </div>
        </div>
        <div class="d-flex justify-content-center my-4" v-if="loading">
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <trainperiod
          v-else
          :trainlist="statlist"
          :tagNr="tagNr"
          v-if="statlist.length > 0 && !loading"
        />

        <div
          class="alert alert-secondary mx-3"
          role="alert"
          v-if="statlist.length == 0 && !loading"
        >
          Inget tåg hittades
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Trainperiod from "@/components/TrainPeriod";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { mapState } from "vuex";

export default {
  name: "train",
  props: ["trainNumber", "stationFrom", "stationTo"],
  data() {
    return {
      statlist: [],
      delayLimit: 5,
      rating: 0,
      tagNr: 0,
      from: "",
      to: "",
      title: "Punktlighet",
      loading: false,
    };
  },

  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },

  components: {
    Trainperiod,
    Navbar,
    PulseLoader,
  },

  mounted() {
    this.tagNr = this.trainNumber;
    this.from = this.stationFrom;
    this.to = this.stationTo;
    if (this.tagNr) {
      this.update();
    }
  },

  methods: {
    update() {
      this.loading = true;
      if (
        this.from != null &&
        this.to != null &&
        this.from.length > 0 &&
        this.to.length > 0
      ) {
        this.$router.push({
          name: "trainWithStations",
          params: {
            trainNumber: this.tagNr,
            stationFrom: this.from,
            stationTo: this.to,
          },
        });
      } else {
        this.$router.push({
          name: "trainWithNumber",
          params: { trainNumber: this.tagNr },
        });
      }
      this.updateTitle();
      this.$axios
        .get(
          this.baseUrl +
            "api/ts_tagperiod.php?tagNr=" +
            this.tagNr +
            "&from=" +
            (this.from ? encodeURIComponent(this.from) : "") +
            "&to=" +
            (this.to ? encodeURIComponent(this.to) : "")
        )
        .then((response) => {
          this.loading = false;
          this.statlist = response.data.data;
          this.updateStats();
        });
    },

    updateTitle() {
      this.title = "Punktlighet tåg " + this.tagNr;
    },

    updateStats() {
      var trains = this.statlist.length;
      var inst = 0;
      var sena = 0;
      for (var i = 0; i < this.statlist.length; i++) {
        if (this.statlist[i].ankdiff == null) {
          inst++;
        } else if (this.statlist[i].ankdiff < this.delayLimit * -1) {
          sena++;
        }
      }
      this.rating = Math.round(((trains - inst - sena) / trains) * 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.train {
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 10px;

  .breadcrumbs {
    font-weight: 600 !important;
    text-decoration: none;
    font-size: 0.95rem;
    padding: 0 15px;

    a {
      color: #3498db;
    }
  }
}

.input-fields {
  border-radius: 4px;
  .input-group {
    input {
      height: 45px;
      width: 250px;
      border-radius: 0;
      text-align: left;
      padding-left: 15px;
      font-size: 17px;
      margin: 0;
      border: 1px solid #ddd;
    }
    input.to {
      border-right: 0;
    }
    .btn-info {
      width: 150px;
      border-radius: 0;
      font-size: 18px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-color: transparent;
    }
  }
}
.delay {
  background: #f1f1f1;
  margin: 15px;
  padding: 5px;
  border-radius: 4px;
  input {
    height: 35px;
    margin-left: 10px;
    padding: 7px;
  }
  p {
    margin: 0;
  }
  span {
    font-weight: 600;
  }
}
.info-text {
  background-color: #faf7f7;
  border: 1px solid #abb2b9;
  padding: 13px;
  border-radius: 4px;
  margin: 2px;
}

@media (max-width: 575.98px) {
  .delay {
    margin-top: -8px;
  }
  .info-text {
    margin-top: -25px;
    font-size: 13px;
  }
  .container {
    margin-top: -25px;
  }
  .table {
    background-color: white;
  }
  .operators[data-v-4df0cf69] {
    padding: 0px;
  }

  .container {
    padding: 0;
  }

  .train {
    margin: 0;
  }

  .input-fields {
    margin: 0;
    padding-top: 0;

    .input-group {
      input,
      .btn-info {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}
@media (max-width: 991.98px) {
  .main {
    background-color: #fff;
  }
  .container {
    margin: 0 auto;
  }
}
@media (max-width: 1199.98px) {
}
</style>

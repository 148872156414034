<template>
  <div class="tracks px-3">
    <p class="h4">Spårstatistik för tåg</p>
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <template v-for="(field, index) in fields">
              <th scope="col" @click="sortColumn(field.key)">
                <div class="header">
                  <span class="name">{{ field.label }}</span>
                  <span class="carets">
                    <i
                      class="bi bi-caret-up-fill"
                      :class="{ active: column === field.key && ascending }"
                    ></i>
                    <i
                      class="bi bi-caret-down-fill"
                      :class="{ active: column === field.key && !ascending }"
                    ></i>
                  </span>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(train, index) in trainlist" :key="index">
            <td>
              <router-link
                :to="{
                  name: 'position',
                  query: { tagNr: tagNr, datum: train.datum },
                }"
                >{{ train.datum }}</router-link
              >
            </td>
            <td>
              <strong>{{ train.spar }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["trainlist", "tagNr"],
  data() {
    return {
      fields: [
        { key: "datum", label: "Datum", sortable: true },
        { key: "spar", label: "Spår", sortable: true },
      ],
      ascending: false,
      column: null,
    };
  },
  methods: {
    getCurrentDate() {
      return moment(this.datum).format("YYYY-MM-DD");
    },

    sortColumn(col) {
      if (this.column === col) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.column = col;
      }

      let ascending = this.ascending;

      this.trainlist.sort((a, b) => {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1;
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tracks {
  background: #fff;
  padding: 15px;
  .h4 {
    font-size: 18px;
    margin-top: 20px;
    font-weight: 600;
  }
  .carets {
    display: flex;
    flex-direction: column;
    .bi {
      font-size: 13px;
      color: #b2babb;
    }
    .bi-caret-down-fill {
      margin-top: -10px;
    }
    .bi.active {
      color: #424949;
    }
  }
  table {
    margin-top: 15px;
    th,
    td {
      padding: 12px;
    }
    thead {
      tr {
        border-top: 1px solid #ddd;
      }
      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &:hover {
        cursor: pointer;
      }
    }
    a {
      color: #3498db;
      font-weight: 600;
      text-decoration: none;
      font-size: 0.925rem;
      &:hover {
        color: #464445cf;
      }
    }
  }

  @media (max-width: 575.98px) {
    .table {
      .status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .red,
        .orange,
        .yellow,
        .green {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
        padding: 5px;
      }
      tbody {
        th {
          font-size: 12px;
        }
        .status .percent {
          font-weight: 600;
        }
        tr > td {
          padding: 3px;
        }
        tr > th {
          padding: 3px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .table {
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
      }
      tbody {
        th {
          font-size: 12px;
        }
        .status .percent {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 1199.98px) {
    .h4 {
      font-size: 17px;
    }
    .table {
      th {
        font-size: 14px;
      }
    }
  }
}
</style>

import { createStore } from "vuex";
import menu from "./modules/menu.store";
// import subscribe from "./modules/subscribe";

export default createStore({
  state() {
    return {
      baseUrl: process.env.VUE_APP_URL,
      visible: false,
    };
  },

  //   plugins: [subscribe],

  modules: {
    menu,
  },
});

<template>
  <div class="operators">
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <template v-for="(field, index) in fields">
              <th scope="col" v-if="field.label" @click="sortColumn(field.key)">
                <div class="header">
                  <span class="name">{{ field.label }}</span>
                  <span class="carets">
                    <i
                      class="bi bi-caret-up-fill"
                      :class="{ active: column === field.key && ascending }"
                    ></i>
                    <i
                      class="bi bi-caret-down-fill"
                      :class="{ active: column === field.key && !ascending }"
                    ></i>
                  </span>
                </div>
              </th>
              <th scope="col" v-else>&nbsp;</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(op, key) in operators" :key="key">
            <td class="operator">
              <router-link
                v-if="op.operatorID > 0"
                :to="{
                  name: 'operator',
                  params: {
                    name: op.operator,
                    operatorID: op.operatorID,
                    date: getCurrentDate(),
                  },
                }"
                >{{ op.operator }}</router-link
              >
              <span v-else>{{ op.operator }}</span>
            </td>
            <td>{{ op.antal }}</td>
            <td>{{ op.inst }}</td>
            <td>{{ op.sen }}</td>
            <td>
              <span class="status">
                <span
                  :class="{
                    red: op.punktlighet < 50,
                    orange: op.punktlighet >= 50 && op.punktlighet < 75,
                    yellow: op.punktlighet >= 75 && op.punktlighet < 90,
                    green: op.punktlighet >= 90,
                  }"
                  >&nbsp;</span
                >
                <span class="percent">{{ op.punktlighet }}%</span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["operators", "date"],
  data() {
    return {
      datum: "",
      fields: [
        { key: "operator", label: "Operatör", sortable: true },
        { key: "antal", label: "Tåg", sortable: true },
        { key: "inst", label: "Inställda", sortable: true },
        { key: "sen", label: "Försenade", sortable: true },
        { key: "punktlighet", sortable: true },
      ],
      ascending: false,
      column: null,
    };
  },
  methods: {
    getCurrentDate() {
      return moment(this.date).format("YYYY-MM-DD");
    },

    sortColumn(col) {
      if (this.column === col) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.column = col;
      }

      let ascending = this.ascending;

      this.operators.sort((a, b) => {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1;
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.operators.ti {
  .h4 {
    font-size: 0.956rem;
  }
  .table {
    thead th {
      font-size: 12px;
    }
    tbody tr th {
      font-size: 14px;
    }
  }
}
.operators {
  background: #fff;
  margin-left: 5px;
  .h4 {
    font-size: 18px;
    font-weight: 600;
  }
  .carets {
    display: flex;
    flex-direction: column;
    .bi {
      font-size: 13px;
      color: #b2babb;
    }
    .bi-caret-down-fill {
      margin-top: -10px;
    }
    .bi.active {
      color: #424949;
    }
  }
  table {
    margin-top: 15px;
    th,
    td {
      padding: 12px;
      font-size: 0.9rem;
    }
    thead {
      tr {
        border-top: 1px solid #ddd;
      }
      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .operator {
      font-size: 15px;
      a {
        color: #3498db;
        font-weight: 600;
        text-decoration: none;
        &:hover {
          color: #464445cf;
        }
      }
    }
    .status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .red,
      .orange,
      .yellow,
      .green {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .percent {
        font-weight: 600;
        font-size: 14px;
      }
      .green {
        background: #27ae60;
      }
      .yellow {
        background: #f1c40f;
      }
      .orange {
        background: #e67e22;
      }
      .red {
        background: #e74c3c;
      }
    }
  }

  @media (max-width: 575.98px) {
    .table {
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
        padding: 5px;
      }
      tbody {
        th {
          font-size: 12px;
        }
        .status .percent {
          font-size: 12px;
        }
        tr > td {
          padding: 5px;
        }
        tr > th {
          padding: 5px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .table {
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
      }
      tbody {
        th {
          font-size: 12px;
        }
        .status .percent {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 1199.98px) {
    .h4 {
      font-size: 17px;
    }
    .table {
      th {
        font-size: 14px;
      }
    }
  }
}
</style>

<template>
  <div class="start-delay p-3">
    <h3 class="mob-smaller">Antal tåg per förseningsorsak</h3>
    <div class="input-fields">
      <div class="mb-2 picker-group">
        <i
          class="bi bi-caret-left-fill"
          @click="prevDate"
          v-if="hasPrevDate()"
        ></i>
        <datepicker
          :locale="lang"
          :upperLimit="upperLimit"
          :lowerLimit="lowerLimit"
          class="form-control"
          :style="style"
          v-model="datum"
          placeholder="Välj datum"
          @update:modelValue="selectedDate"
          :inputFormat="parseFormat"
        ></datepicker>
        <i
          class="bi bi-caret-right-fill"
          @click="nextDate"
          v-if="hasNextDate()"
        ></i>
      </div>
      <div class="mb-3">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="delays"
            id="delaysDay"
            value="day"
            v-model="selected"
          />
          <label class="form-check-label" for="delaysDay">Dag</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="delays"
            id="delaysWeek"
            value="week"
            v-model="selected"
          />
          <label class="form-check-label" for="delaysWeek">Vecka</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="delays"
            id="delaysMonth"
            value="month"
            v-model="selected"
          />
          <label class="form-check-label" for="delaysMonth">Månad</label>
        </div>
      </div>
      <p class="info-heading">Antal tåg som försenats: {{ summa }}.</p>
      <p>
        Här redovisas alla förseningar, stora som små. Många tåg lyckas komma
        fram till slutstationen i tid även om de drabbas av förseningar på
        vägen.
      </p>
    </div>
    <TrainDelays :operators="statlist" :date="this.datum" :summa="summa" />
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vue3-datepicker";
import { sv } from "date-fns/locale";
import TrainDelays from "@/components/TrainDelays";
import { mapState } from "vuex";

export default {
  name: "StartDelays",
  data() {
    return {
      lang: sv,
      lowerLimit: new Date(2018, 6, 1),
      upperLimit: moment().subtract(1, "day").toDate(),
      datum: moment().subtract(1, "day").toDate(),
      statlist: [],
      summa: 0,
      loading: false,
      componentKey: 0,
      stats: [],
      size: "18px",
      selected: "day",
      step: "day",
      pickerFormat: "EEE yyyy-MM-dd",
      style: {
        borderRadius: 0,
        height: "40px",
        minWidth: "230px",
      },
    };
  },

  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),

    parseFormat() {
      if (this.selected === "day") {
        this.step = "day";
        this.pickerFormat = "EEE yyyy-MM-dd";
        this.update();
      } else if (this.selected === "week") {
        this.step = "week";
        this.pickerFormat = "I"; //I - EEE d MMM yyyy
        this.update();
      } else if (this.selected === "month") {
        this.step = "month";
        this.pickerFormat = "MMMM yyyy";
        this.update();
      }

      return this.pickerFormat;
    },
  },

  components: {
    Datepicker,
    TrainDelays,
  },

  methods: {
    selectedDate(date) {
      this.datum = date;
      this.update();
    },

    prevDate() {
      this.datum = moment(this.datum).subtract(1, this.step).toDate();
      this.update();
    },

    nextDate() {
      this.datum = moment(this.datum).add(1, this.step).toDate();
      this.update();
    },

    hasNextDate() {
      if (this.step == "day") {
        return moment(this.datum) < moment().subtract(2, "day");
      } else if (this.step == "week") {
        var mondayDist = moment().isoWeekday() - 1;
        var monday = moment();
        if (mondayDist > 0) {
          monday = moment().subtract(mondayDist, "day");
        }
        return moment(this.datum) < monday;
      } else if (this.step == "month") {
        var curmonth = moment().format("YYYY-MM") + "-01";
        return moment(this.datum) < moment(curmonth);
      }
    },

    hasPrevDate() {
      return moment(this.datum) > moment("2018-07-02");
    },

    update() {
      var url = "";
      if (this.step == "day") {
        url =
          this.baseUrl +
          "api/ts_delays.php?datum=" +
          moment(this.datum).format("YYYY-MM-DD");
      } else if (this.step == "week") {
        url =
          this.baseUrl +
          "api/ts_delays_vecka.php?datum=" +
          moment(this.datum).format("YYYY-MM-DD");
      } else if (this.step == "month") {
        url =
          this.baseUrl +
          "api/ts_delays_manad.php?datum=" +
          moment(this.datum).format("YYYY-MM-DD");
      }
      this.$axios.get(url).then((response) => {
        this.statlist = response.data.data;
        this.summa = response.data.summa;
      });
    },

    daySelected() {},

    weekSelected() {},

    monthSelected() {},

    onRadioChaned() {},
  },
};
</script>

<style lang="scss" scoped>
.start-delay {
  background-color: white;
  padding: 8px;
  margin: 15px auto;

  h3 {
    font-weight: 600;
    font-size: 1.5rem;
    padding: 15px 15px 0;
  }
}
.input-fields {
  padding-bottom: 20px;
  .picker-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    .bi {
      font-size: 30px;
      cursor: pointer;
      &:hover {
        color: #99a3a4;
      }
    }
  }
  .input-group {
    a {
      margin-top: 8px;
    }
  }
}
.info-heading {
  margin-top: 15px;
  font-weight: bold;
  font-size: 14px;
}
.arrows {
  max-height: 20px;
  margin-top: 2px;
}
.btn-info {
  font-weight: bold;
  background-color: #6b93bb;
  border-color: #587592;
}
.btn-info:hover {
  background-color: #5e80a3;
  border-color: #587592;
}
@media (max-width: 575.98px) {
  .mob-smaller {
    font-size: 1.2rem;
    margin-left: 10px;
  }
}
</style>

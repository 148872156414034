<template>
  <div class="trains">
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <template v-for="(field, index) in fields">
              <th scope="col" @click="sortColumn(field.key)">
                <div class="header">
                  <span class="name">{{ field.label }}</span>
                  <span class="carets">
                    <i
                      class="bi bi-caret-up-fill"
                      :class="{ active: column === field.key && ascending }"
                    ></i>
                    <i
                      class="bi bi-caret-down-fill"
                      :class="{ active: column === field.key && !ascending }"
                    ></i>
                  </span>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(operator, index) in operators" :key="index">
            <td>
              <span class="status">
                <span
                  :class="{
                    red: operator.delay <= -30 || operator.delay == null,
                    orange: operator.delay <= -15,
                    yellow: operator.delay <= -5,
                    green: operator.delay > -5,
                  }"
                  >&nbsp;</span
                >
                {{ operator.koncept }}
              </span>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'position',
                  query: { tagNr: operator.tagNr, datum: date },
                }"
                >{{ operator.tagNr }}</router-link
              >
            </td>
            <td>{{ operator.from }}</td>
            <td>{{ operator.till }}</td>
            <td>{{ operator.fromTime }}</td>
            <td>{{ operator.toTime }}</td>
            <td>
              <span
                :class="{
                  late: operator.delay < 0,
                  early: operator.delay >= 0,
                }"
                >{{
                  operator.delay > -1 ? operator.delay : operator.delay * -1
                }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["operators", "date"],
  data() {
    return {
      fields: [
        { key: "koncept", label: "Tågtyp", sortable: true },
        { key: "tagNr", label: "Tågnr", sortable: true },
        { key: "from", label: "Från", sortable: true },
        { key: "to", label: "Till", sortable: true },
        { key: "fromTime", label: "Plan. avg", sortable: true },
        { key: "toTime", label: "Plan. ank", sortable: true },
        { key: "delay", label: "Diff", sortable: true },
      ],
      ascending: false,
      column: null,
    };
  },

  methods: {
    trainOnTimePercentage(total, totalDelay) {
      return Math.round(((total - totalDelay) / total) * 100);
    },

    sortColumn(col) {
      if (this.column === col) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.column = col;
      }

      let ascending = this.ascending;

      this.trainlist.sort((a, b) => {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1;
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.trains {
  .carets {
    display: flex;
    flex-direction: column;
    .bi {
      font-size: 13px;
      color: #b2babb;
    }
    .bi-caret-down-fill {
      margin-top: -10px;
    }
    .bi.active {
      color: #424949;
    }
  }

  table {
    margin-top: 15px;
    th,
    td {
      padding: 12px;
    }
    thead {
      tr {
        border-top: 1px solid #ddd;
      }
      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &:hover {
        cursor: pointer;
      }
    }
    a {
      color: #3498db;
      font-weight: 600;
      text-decoration: none;
      font-size: 0.925rem;
      &:hover {
        color: #464445cf;
      }
    }
  }
}
.late {
  color: #e74c3c;
}
.early {
  color: #27ae60;
}

.operators {
  background: #fff;
  padding: 15px;
  table {
    margin-top: 15px;
    th {
      border-bottom: 0;
    }
    .operator {
      font-size: 15px;
      a {
        color: #148f77;
        font-weight: 600;
        &:hover {
          color: #464445cf;
          text-decoration: none;
        }
      }
    }
    .status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .red,
      .orange,
      .yellow,
      .green {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .percent {
        font-weight: 600;
        font-size: 14px;
      }
      .green {
        background: #27ae60;
      }
      .yellow {
        background: #f1c40f;
      }
      .orange {
        background: #e67e22;
      }
      .red {
        background: #e74c3c;
      }
    }
  }

  @media (max-width: 575.98px) {
    .table {
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
        padding: 5px;
      }
      tbody {
        th {
          font-size: 12px;
        }
        .status .percent {
          font-size: 12px;
        }
        tr > td {
          padding: 5px;
        }
        tr > th {
          padding: 5px;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .table {
      thead th {
        font-size: 0.75rem;
        font-weight: 600;
      }
      tbody {
        th {
          font-size: 12px;
        }
        .status .percent {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 1199.98px) {
    .h4 {
      font-size: 17px;
    }
    .table {
      th {
        font-size: 14px;
      }
    }
  }
}
</style>

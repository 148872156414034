<template>
    <div class="start-trains p-3">
      <h3 class="mob-smaller">Punktlighet per tågoperatör</h3>
      <div class="input-fields">
        <div class="mb-2 picker-group">
          <i
            class="bi bi-caret-left-fill"
            @click="prevDate"
            v-if="hasPrevDate()"
          ></i>
          <datepicker
            :locale="lang"
            :upperLimit="upperLimit"
            :lowerLimit="lowerLimit"
            class="form-control"
            :style="style"
            v-model="datum"
            :value="displayValue"
            placeholder="Välj datum"
            @update:modelValue="selectedDate"
            :inputFormat="parseFormat"
          ></datepicker>
          <i
            class="bi bi-caret-right-fill"
            @click="nextDate"
            v-if="hasNextDate()"
          ></i>
        </div>
        <div class="mb-3">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="calendar"
              id="day"
              value="day"
              v-model="selected"
            />
            <label class="form-check-label" for="day">Dag</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="calendar"
              id="week"
              value="week"
              v-model="selected"
            />
            <label class="form-check-label" for="week">Vecka</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="calendar"
              id="month"
              value="month"
              v-model="selected"
            />
            <label class="form-check-label" for="month">Månad</label>
          </div>
        </div>
        <p class="info-heading" style="font-size: 13px">
          Antal tåg i Sverige: {{ this.totalTag }}, varav
          {{ this.totalInst }} inställda och {{ this.totalDelayed }} försenade.
          Punktlighet:
          {{
            Math.round(
              ((this.totalTag - this.totalInst - this.totalDelayed) /
                this.totalTag) *
                100
            )
          }}%
        </p>
        <p>
          Ett punktligt tåg är ett tåg som kommit till slutstationen senast fem
          minuter efter planerad tid. I antalet tåg ingår inställda tåg
          förutsatt att de ställts in samma dag eller dagen före.
        </p>
      </div>
      <train-operators
        :operators="statlist"
        :date="this.datum"
        class="table-responsive table-mob"
      />
    </div>
</template>

<script>
import TrainOperators from "@/components/TrainOperators";
import Datepicker from "vue3-datepicker";
import { sv } from "date-fns/locale";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "StartTrains",
  data() {
    return {
      lang: sv,
      lowerLimit: new Date(2018, 6, 1),
      upperLimit: moment().subtract(1, "day").toDate(),
      datum: moment().subtract(1, "day").toDate(),
      statlist: [],
      totalTag: 0,
      totalInst: 0,
      totalDelayed: 0,
      loading: false,
      componentKey: 0,
      stats: [],
      size: "18px",
      selected: "day",
      step: "day",
      pickerFormat: "EEE yyyy-MM-dd",
      style: {
        borderRadius: 0,
        height: "40px",
        minWidth: "230px",
      },
      displayValue: null,
    };
  },

  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),

    parseFormat() {
      moment.locale("sv-SE");
      if (this.selected === "day") {
        this.step = "day";
        // this.pickerFormat = "EEE yyyy-MM-dd";
        this.displayValue = moment(this.datum).format("ddd, YYYY-MM-DD");
        this.update();
      } else if (this.selected === "week") {
        this.step = "week";
        // this.pickerFormat = "I"; //I - EEE d MMM yyyy
        this.displayValue = "Vecka " + moment(this.datum).format("w, YYYY");
        this.update();
      } else if (this.selected === "month") {
        this.step = "month";
        // this.pickerFormat = "MMMM yyyy";
        this.displayValue = moment(this.datum).format("MMMM YYYY");
        this.update();
      }

      return this.pickerFormat;
    },
  },

  components: {
    TrainOperators,
    Datepicker,
  },

  mounted() {
    moment.locale("sv-SE");
  },

  methods: {
    selectedDate(date) {
      this.datum = date;
      this.update();
    },

    prevDate() {
      this.datum = moment(this.datum).subtract(1, this.step).toDate();
    },

    nextDate() {
      this.datum = moment(this.datum).add(1, this.step).toDate();
    },

    hasNextDate() {
      if (this.step == "day") {
        return moment(this.datum) < moment().subtract(2, "day");
      } else if (this.step == "week") {
        var mondayDist = moment().isoWeekday() - 1;
        var monday = moment();
        if (mondayDist > 0) {
          monday = moment().subtract(mondayDist, "day");
        }
        return moment(this.datum) < monday;
      } else if (this.step == "month") {
        var curmonth = moment().format("YYYY-MM") + "-01";
        return moment(this.datum) < moment(curmonth);
      }
    },

    hasPrevDate() {
      return moment(this.datum) > moment("2018-07-02");
    },

    update() {
      var url = "";
      if (this.step == "day") {
        url =
          this.baseUrl +
          "api/ts_stat.php?datum=" +
          moment(this.datum).format("YYYY-MM-DD");
      } else if (this.step == "week") {
        url =
          this.baseUrl +
          "api/ts_statvecka.php?datum=" +
          moment(this.datum).format("YYYY-MM-DD");
      } else if (this.step == "month") {
        url =
          this.baseUrl +
          "api/ts_statmanad.php?datum=" +
          moment(this.datum).format("YYYY-MM-DD");
      }

      this.$axios.get(url).then((response) => {
        this.statlist = response.data;
        this.totalTag = 0;
        this.totalInst = 0;
        this.totalDelayed = 0;
        for (var i = 0; i < this.statlist.length; i++) {
          this.totalTag += this.statlist[i].antal;
          this.totalInst += this.statlist[i].inst;
          this.totalDelayed += this.statlist[i].sen;
        }
      });
    },

    daySelected() {},

    weekSelected() {},

    monthSelected() {},

    onRadioChanged() {},
  },
};
</script>

<style lang="scss" scoped>
.start-trains {
  background-color: white;
  padding: 8px;
  margin: 15px auto;

  h3 {
    font-weight: 600;
    font-size: 1.5rem;
    padding: 15px 15px 0;
  }
}
.input-fields {
  padding-bottom: 0px;
  .picker-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    .bi {
      font-size: 30px;
      cursor: pointer;
      &:hover {
        color: #99a3a4;
      }
    }
  }
  .input-group {
    a {
      margin-top: 8px;
    }
  }
}
.info-heading {
  margin-top: 15px;
  font-weight: bold;
  font-size: 14px;
}
.arrows {
  max-height: 20px;
  margin-top: 2px;
}
.btn-info {
  font-weight: bold;
  background-color: #6b93bb;
  border-color: #587592;
}
.btn-info:hover {
  background-color: #5e80a3;
  border-color: #587592;
}
@media (max-width: 575.98px) {
  .mob-smaller {
    font-size: 1.2rem;
    margin-left: 10px;
  }
  .table {
    thead th {
      font-size: 0.4rem;
      font-weight: 600;
      padding: 2px;
    }
    tr {
      font-size: 12px;
    }
    tbody {
      th {
        font-size: 12px;
      }
      .status .percent {
        font-size: 10px;
      }
      tr > td {
        padding: 2px;
      }
      tr > th {
        padding: 2px;
      }
    }
  }
  .table-mob {
    margin-left: -10px;
  }
}

@media (max-width: 991.98px) {
  .container {
    padding-right: 2px;
    padding-left: 2px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .table {
    thead th {
      font-size: 0.75rem;
      font-weight: 600;
    }
    tbody {
      th {
        font-size: 12px;
      }
      .status .percent {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .h4 {
    font-size: 17px;
  }
  .table {
    th {
      font-size: 14px;
    }
  }
}
</style>

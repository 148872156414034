<template>
  <div class="main">
    <!-- <vue-headful title="Förklaring av orsak" /> -->
    <Navbar />
    <div class="container">
      <h1>{{ orsak }}</h1>
      <div v-if="orsak == 'Signal'">
        <p>
          Signaler används för att säkerställa att tågen inte krockar, och är
          ett krav för att få köra i normal hastighet.
        </p>
        <p>
          För att signalen ska lysa grönt krävs att den har ström, det inte
          finns några hinder på sträckan bortom signalen och att alla växlar
          ligger rätt.
        </p>
      </div>
      <div v-if="orsak == 'Växel'">
        <p>
          Växlar används för att få tågen att byta spår. Växeln består av
          rörliga delar som antingen leder tåget rakt fram eller åt sidan till
          ett annat spår. Åker man rakt fram så innebär inte växeln någon
          reducerad hastighet, men ska man byta spår så finns det växlar med
          hastighetsbegränsningar mellan 40 och 130 km/h baserat på hur skarp
          kurvan över till det andra spåret är.
        </p>
        <p>
          Om växelns rörliga delar (växeltungorna) inte är i något av ändlägena
          skulle tåget spåra ur om det försökte passera växeln. Därför övervakas
          växlarnas lägen av signaler. Om någon av växlarna längs tågets färdväg
          inte ligger i rätt läge så lyser signalen före rött och tåget stannar.
        </p>
        <p>
          Vintertid kan det vara stora snömängder eller isklumpar som gör att
          växeln inte lyckas hamna i ändläge. Växlar är eluppvärmda.
        </p>
        <p>
          När ett tåg stannar för ett växelfel kan föraren kan gå ut och kolla
          om han/hon ser några hinder för växelns rörliga delar och plocka bort
          dessa för att växeln ska fungera normalt.
        </p>
        <p>
          Om felet har att göra med den elektroniska övervakningen av växelns
          läge kan inte föraren åtgärda det utan då får man tillstånd att
          kontrollera så att växeln ligger i ändläge i rätt riktning och
          däreftera passera i max 10 km/h över växeln.
        </p>
      </div>
      <div v-if="orsak == 'Utanför Sverige'">
        <p>
          Förseningen har uppstått i Norge eller Danmark och då loggas inte
          orsaken i Sverige.
        </p>
      </div>
      <div v-if="orsak == 'Uppehållstid överskriden'">
        <p>
          Tåget har stått stilla vid plattformen längre tid än vad som var
          planerat. Vanliga orsaker till detta är:
        </p>
        <ul>
          <li>Fler resenärer än vanligt.</li>
          <li>Mer bagage än vanligt.</li>
          <li>Dörrar ur funktion på tåget.</li>
          <li>Färre vagnar i tåget än vanligt.</li>
          <li>Annan typ av vagnar (med färre dörrar).</li>
        </ul>
      </div>
      <div v-if="orsak == 'Trafikledningen'">
        <p>
          Trafikledningen hos Trafikverket styr växlar och signaler. Faktorer
          som gör att deras jobb tar längre tid och därmed orsakar förseningar
          är:
        </p>
        <ul>
          <li>Underbemanning.</li>
          <li>Driftstörningar i datasystem.</li>
          <li>
            Manuella, långsammare rutiner, som ibland krävs av säkerhetsskäl.
          </li>
        </ul>
      </div>
      <div v-if="orsak == 'Fordonsproblem'">
        <p>
          Olika former av tekniska problem på lok och vagnar som gör att tåget
          inte kan gå som planerat. Det kan röra sig om:
        </p>
        <ul>
          <li>
            Dörrar och utfällbara fotsteg - allt måste vara stängt och låst för
            att tåget ska kunna köra. Speciellt vintertid kan det ibland gå
            trögt att få igen dörrar och fotsteg när det är is ivägen.
          </li>
          <li>
            Hjulskada - hjulen måste vara runda för att inte förstöra rälsen. Om
            hjulen låser sig vid inbromsning slits de extra mycket på ett ställe
            och det uppstår en så kallad hjulplatta.
          </li>
          <li>
            Tjuvbroms - om bromsen ligger till medan man kör så kan det leda
            till hjulskador.
          </li>
        </ul>
      </div>
      <div v-if="orsak == 'Tågoperatör'">
        <p>
          Tågoperatören är järnvägsföretaget som ansvarar för att köra tåget. De
          vanligaste förseningar som de kan ge upphov till är om deras personal
          inte är på rätt plats i rätt tid. Ibland kan det också handla om att
          resenärerna inte är på rätt plats i rätt tid på grund av att de kommer
          med ett försenat tåg. Om tågoperatören då väljer att låta tåget vänta
          så hamnar förseningen i den här kategorin.
        </p>
      </div>
      <div v-if="orsak == 'Okänd orsak'">
        <p>
          Det finns ingen uppenbar anledning till varför tåget blev försenat och
          tågoperatören har inte hört av sig och berättat orsaken.
        </p>
        <p>
          Det är Trafikverket som matar in förseningsorsaken i sitt datasystem.
          Hög arbetsbelastning kan göra att man inte hinner ringa föraren på
          tåget och fråga, eller att föraren inte hinner ringa och berätta.
        </p>
      </div>
      <div v-if="orsak == 'Obehöriga i spårområdet'">
        <p>
          Tåg har normalt en stoppsträcka på 1-2 kilometer och har därför ingen
          möjlighet att stanna om någon skulle vara i vägen. Enligt lag får
          allmänheten därför inte befinna sig i spårområdet. Upptäcks någon
          obehörig i spårområdet stoppas trafiken och polisen tillkallas.
        </p>
      </div>
      <div v-if="orsak == 'Spår'">
        <p>
          Spåret kan bli skadat av extrema temperaturer (både låga och höga)
          eller av järnvägsfordon med hjulskada.
        </p>
        <p>Spår med låg hastighetsbegränsning är mer känsliga.</p>
        <p>
          Räl med hög standard väger 60 kg per meter och sitter fast på
          betongslipers som väger cirka 200 kg.
        </p>
      </div>
      <div v-if="orsak == 'Kapacitetsbrist'">
        <p>
          På många sträckor, i synnerhet i storstadsområdena, går det många tåg
          och man planerar för ett högt kapacitetsutnyttjande. Om något tåg blir
          försenat så drabbar det lätt andra tåg i och med att spårkapaciteten
          då inte räcker.
        </p>
      </div>
      <div v-if="orsak == 'Olycka/tillbud'">
        <p>
          Det sker väldigt få tågolyckor i Sverige. De olyckor som sker är
          oftast kollision med vilda djur, obehöriga i spåret eller vägfordon.
        </p>
        <p>
          För mer information, läs Transportstyrelsens
          <a
            href="https://www.transportstyrelsen.se/sv/publikationer-och-rapporter/rapporter/jarnvag/sakerhetsrapport-jarnvag-2017/"
            >Säkerhetsrapport järnväg 2017</a
          >.
        </p>
      </div>
      <div v-if="orsak == 'Banarbete'">
        <p>
          Banarbeten är i allmänhet planerade i förväg och tågen har då fått
          längre restid i sina tidtabeller på grund av nedsatt hastighet genom
          banarbetsområdet, att spårkapaciteten är begränsad eller att man kör
          en annan väg. Men ibland uppstår akuta situtationer där man inte
          hinner planera i förväg.
        </p>
      </div>
      <div v-if="orsak == 'Natur/Väder'">
        <p>Väderfenomen som kan ge upphov till trafikstörningar:</p>
        <ul>
          <li>Spårhalka - i synnerhet på hösten gör löven det väldigt halt.</li>
          <li>
            Bränder - i synnerhet på sommaren. Tågens bromsar kan slå gnistor
            som tänder på den torra marken.
          </li>
          <li>
            Kyla - blir det för kallt ställer man in trafiken på sträckor där
            det inte finns landsvägar i närheten, eftersom det skulle ta för
            lång tid för hjälp att nå fram om tåget havererar.
          </li>
          <li>
            Storm - en del sträckor har träd för nära spårområdet och där
            ställer man in trafiken i förebyggande syfte.
          </li>
        </ul>
      </div>
      <div v-if="orsak == 'Sjukdomsfall eller sabotage'">
        <p>
          Om en resenär ombord blir akut sjuk så man behöver invänta ambulans.
          Sabotage är när någon medvetet förstör, exempelvis drar i nödbroms
          utan anledning, placerar hinder på spåret eller stjäl elledningar.
        </p>
      </div>
      <div v-if="orsak == 'Akut kontroll fordon/järnväg'">
        <p>
          Om man befarar att ett järnvägsfordon har hjulskada eller trasig
          strömavtagare så får det köra med nedsatt hastighet till en lämplig
          plats där man kan kontrollera. Finns det ingen skada kan man fortsätta
          normalt. Finns det en skada behöver man kontrollera spåret där tåget
          åkt. Det sker normalt genom att tåget bakom får köra i max 30 km/h
          samtidigt som föraren tittar noga på spåret.
        </p>
      </div>
      <div v-if="orsak == 'Järnvägsövergång'">
        <p>
          Om en järnvägsövergång är trasig, ofta för att ett vägfordon har kört
          på en bom eller en stolpe, så kommer tågen att köra långsamt och
          stanna före och kontrollera att de kan passera utan att köra på någon.
        </p>
        <p>
          Observera dock att om du skulle råka stanna på spåret med bilen när
          ett tåg är på väg så är det mycket bättre köra sönder bommen än att
          stå kvar och krocka med tåget.
        </p>
      </div>
      <div v-if="orsak == 'Elförsörjning'">
        <p>
          Ström behövs till mycket på järnvägen. Inte bara för att köra tågen
          utan också för signaler, växlar och belysning.
        </p>
      </div>
      <div v-if="orsak == 'Kontaktledning'">
        <p>
          Kontaktledning är det som hänger ovanför spåren och förser tågen med
          elektricitet. Eldrivna järnvägsfordon har en strömavtagare som ligger
          an mot kontaktledningen. Fel på strömavtagaren eller på
          kontaktledningen kan leda till att kontaktleldningen går sönder och
          faller ner. I och med att den är en högspänningsledning (15 000 volt)
          är den livsfarlig tills räddningstjänsten eller reparatörer har jordat
          den, men inne i tåget är man säker.
        </p>
      </div>
      <div v-if="orsak == 'Depå'">
        <p>
          Depån är där tågen servas. De tvättas och städas, man tömer toaletter
          och fyller på vattentankarna. Även reparationer, underhåll och tillsyn
          utförs. I depån brukar det finnas reservfordon så man har möjlighet
          att komma tillrätta med förseningar, men det kan också uppstå
          förseningar om det uppstår tidsbrist på grund av att tågen är
          försenade dit eller att reparationer tar längre tid än beräknat.
        </p>
      </div>
      <div v-if="orsak == 'Övrig infrastruktur'">
        <p>
          Det som klassas som övrig infrastruktur är plattformar, banvallen,
          tunnlar, staket och hus. Det är inte så ofta de sakerna leder till
          förseningar.
        </p>
      </div>
      <div v-if="orsak == 'Djur'">
        <p>
          Det är i synnerhet älgar som är såpass stora att de kan orsaka skador
          på tåget som leder till förseningar. Men förseningar kan också uppstå
          om man får reda på att tama djur rymt och trafiken stoppas eller
          hastigheten sänks.
        </p>
      </div>
      <div v-if="orsak == 'Öppningsbar bro'">
        <p>
          Om en öppningsbar bro inte kan stängas eller den inte indikerar att
          allt är på plats så behöver reparatörer kallas ut. En lokförare får
          inte på egen hand bedöma om en öppningsbar bro är säker att åka över.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "description",
  props: ["orsak"],
  components: {
    Navbar,
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: white;
  margin-top: 15px;
}
</style>

<template>
  <div class="main">
    <!-- <vue-headful :title="title" /> -->
    <navbar />
    <div class="container">
      <div class="operators p-3">
        <p class="breadcrumbs">
          <span
            ><router-link :to="{ name: 'home' }">Start</router-link> /
            <router-link :to="{ name: 'statistik' }">Statistik</router-link> /
          </span>
          Tågoperatör
        </p>

        <h4 class="stats-head">Punktlighet vid slutstation</h4>
        <template v-if="!loading && !loadedOperatorData">
          <div class="spinner">
            <pulse-loader :size="size"></pulse-loader>
            <p class="info">Hämtar statistik ...</p>
          </div>
        </template>
        <div class="chart-wrapper" v-if="loading && loadedOperatorData">
          <line-chart
            :width="1100"
            :height="350"
            :labels="labels"
            :datasets="datasets"
            :options="options"
            :key="updateKey"
          />
        </div>
        <hr />
        <div class="picker-group">
          <i
            class="bi bi-caret-left-fill"
            @click="prevDate"
            v-if="hasPrevDate()"
          ></i>
          <datepicker
            :locale="lang"
            :upperLimit="upperLimit"
            class="form-control"
            :style="style"
            v-model="datum"
            placeholder="Välj datum"
            @update:modelValue="selectedDate"
          ></datepicker>
          <i
            class="bi bi-caret-right-fill"
            @click="nextDate"
            v-if="hasNextDate()"
          ></i>
        </div>
        <div class="desc">
          <div class="d-flex flex-row mb-2">
            <div class="p-2 fw-bold">Förseningsminuter:</div>
            <div class="p-2">
              <input
                type="number"
                class="form-control"
                placeholder="Förseningsminuter"
                v-model="delayLimit"
                @change="updateStats"
              />
            </div>
            <div class="p-2">
              <p class="text-muted">
                Justera gränsen för när ett tåg ska räknas som försenat och se
                hur punktligheten ändras.
              </p>
            </div>
          </div>
          <div class="d-flex flex-row mb-2">
            <div class="p-2 fw-bold">Punktlighet:</div>
            <div class="p-2">{{ this.rating ? this.rating : 0 }}%</div>
          </div>
        </div>

        <trains :operators="statlist" :date="date" v-if="statlist.length > 0" />

        <div
          class="alert alert-secondary mx-3"
          role="alert"
          v-if="statlist.length == 0 && !loading"
        >
          Hittar ingen tågoperatör
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Trains from "@/components/Trains";
import Datepicker from "vue3-datepicker";
import { sv } from "date-fns/locale";
import moment from "moment";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import LineChart from "@/components/charts/LineChart.vue";
import { mapState } from "vuex";

export default {
  name: "operator",
  props: ["name", "operatorID", "date"],
  data() {
    return {
      datum: null,
      lang: sv,
      statlist: [],
      delayLimit: 5,
      rating: 0,
      upperLimit: moment().subtract(1, "day").toDate(),
      loading: false,
      loadedOperatorData: false,
      stats: [],
      statsAll: [],
      size: "18px",
      title: "Operatör",
      labels: [],
      datasets: [
        {
          label: "Hela Sverige",
          data: [],
          fill: false,
          backgroundColor: "#f87979",
          borderColor: "#f87979",
          tension: 0.1,
          pointHoverBorderWidth: 5,
          pointRadius: 0,
        },
        {
          label: null,
          data: [],
          fill: false,
          tension: 0.1,
          borderColor: "#2980B9",
          backgroundColor: "#2980B9",
          pointRadius: 0,
        },
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            autoSkip: false,
            ticks: {
              maxTicksLimit: 0,
            },
          },
          y: {
            autoSkip: false,
            beginAtZero: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
              scaleBeginAtZero: true,
              stepSize: 25,
              callback: function (value) {
                return value + " %";
              },
            },
          },
        },
      },
      updateKey: 0,
      style: {
        borderRadius: 0,
        height: "40px",
        minWidth: "230px",
      },
    };
  },

  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },

  components: {
    Trains,
    Datepicker,
    Navbar,
    PulseLoader,
    LineChart,
  },

  mounted() {
    this.datasets[1].label = this.name;
    this.datum = moment(this.date).toDate();
    this.update();
    this.fetchOperatorData();
    this.fetchTrainOperatorsData();
  },

  methods: {
    selectedDate(date) {
      this.datum = date;
      this.update();
    },

    prevDate() {
      this.datum = moment(this.datum).subtract(1, "day").toDate();
      this.update();
    },

    nextDate() {
      this.datum = moment(this.datum).add(1, "day").toDate();
      this.update();
    },

    hasNextDate() {
      return moment(this.datum) < moment().subtract(2, "day");
    },

    hasPrevDate() {
      return moment(this.datum) > moment("2018-07-02");
    },

    update() {
      this.updateTitle();
      this.$axios
        .get(
          this.baseUrl +
            "api/ts_tag.php?operatorID=" +
            this.operatorID +
            "&datum=" +
            moment(this.datum).format("YYYY-MM-DD")
        )
        .then((response) => {
          this.statlist = response.data;
          this.updateStats();
        });
    },

    updateTitle() {
      this.title =
        "Tåg från " + this.name + " " + moment(this.datum).format("YYYY-MM-DD");
    },

    updateStats() {
      var trains = this.statlist.length;
      var inst = 0;
      var sena = 0;
      for (var i = 0; i < this.statlist.length; i++) {
        if (this.statlist[i].delay == null) {
          inst++;
        } else if (this.statlist[i].delay < this.delayLimit * -1) {
          sena++;
        }
      }
      this.rating = Math.round(((trains - inst - sena) / trains) * 100);
    },

    fetchOperatorData() {
      this.loadedOperatorData = false;
      this.$axios
        .get(
          this.baseUrl + "api/ts_operator_all.php?operatorID=" + this.operatorID
        )
        .then((res) => {
          this.stats = res.data;
          this.loadedOperatorData = true;
          if (this.stats.length > 0) {
            this.labels = this.stats.map((stat) =>
              moment(stat.datum).format("MMM D")
            );
            this.datasets[1].data = this.stats.map((stat) => stat.punktlighet);
            this.updateKey++;
          }
        });
    },

    fetchTrainOperatorsData() {
      this.loading = false;
      this.$axios.get(this.baseUrl + "api/ts_sverige.php").then((res) => {
        this.statsAll = res.data;
        this.loading = true;
        if (this.statsAll.length > 0) {
          this.datasets[0].data = this.statsAll.map(
            (statAll) => statAll.punktlighet
          );
          this.updateKey++;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.operators {
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 10px;

  .breadcrumbs {
    font-weight: 600 !important;
    text-decoration: none;
    font-size: 0.95rem;
    padding: 0 15px;

    a {
      color: #3498db;
    }
  }

  .picker-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    .bi {
      font-size: 30px;
      cursor: pointer;
      &:hover {
        color: #99a3a4;
      }
    }
  }
}

.stats-head {
  font-size: 18px;
  margin-top: 20px;
  font-weight: 600;
}
.desc {
  margin: 20px 0;
}
</style>

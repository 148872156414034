<template>
  <div class="main">
    <!-- <vue-headful title="Information om tågstatistik" /> -->
    <navbar />
    <div class="container">
      <div class="statistic p-3">
        <p class="breadcrumbs">
          <span><router-link :to="{ name: 'home' }">Start</router-link> / </span
          >Statistik
        </p>
        <div class="content">
          <p>
            Nedanstående statistik finns tillgänglig från 2018-07-01 till och
            med i går. Datat kommer från Trafikverket och fylls automatiskt på
            dagligen.
          </p>
          <h4>
            <router-link :to="{ name: 'home' }">Tågoperatörer</router-link>
          </h4>
          <p>
            Se punktligheten per tågoperatör och dag. Klicka på tågoperatören
            för att få information om alla deras tåg under dagen. Klicka på
            tåget för detaljerad information om hur tidtabellen följts och
            eventuella förseningsorsaker.
          </p>
          <h4><router-link :to="{ name: 'train' }">Tågnummer</router-link></h4>
          <p>
            Sök på ett specifikt tågnummer och se hur det har gått under hela
            perioden sedan 2018-07-01. Det är även möjligt att begränsa sig till
            en del av tågets sträcka. För varje datum ser man när tåget
            verkligen avgick och ankom samt hur många minuters avvikelse det var
            från tidtabellen. Man kan klicka på datumet för att få upp
            detaljerad information om tåget den dagen.
          </p>
          <h4><router-link :to="{ name: 'route' }">Sträcka</router-link></h4>
          <p>
            Sök på en sträcka och få upp alla tåg som går den sträckan. För
            varje tåg visas en punktlighetsprocent som visar hur ofta just det
            tåget brukar komma i tid.
          </p>
          <h4><router-link :to="{ name: 'tracks' }">Spår</router-link></h4>
          <p>
            Sök på en station och ett tågnummer och se vilket spår som tåget
            brukar gå från eller komma till. Observera att det inte är någon
            garanti för att tåget alltid kommer att gå från samma spår,
            spårnummer kan ändras ända fram tills tåget ankommit/avgått, men
            statistiken kan ju i alla fall ge en indikering på hur det brukar
            vara.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import { useHead } from "@vueuse/head";

useHead({
  title: "Trafikupplysning | Information om tågstatistik",
  meta: [
    { property: "og:type", content: "website" },
    {
      property: "og:title",
      content: "Trafikupplysning | Information om tågstatistik",
    },
    {
      property: "og:image",
      content: `${require("@/assets/statistic.png")}`,
    },
    { property: "og:description", content: "Information om tågstatistik" },
  ],
});

export default {
  components: {
    Navbar,
  },
};
</script>

<style lang="scss" scoped>
.statistic {
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 10px;

  .breadcrumbs {
    font-weight: 600 !important;
    text-decoration: none;
    font-size: 0.95rem;
    padding: 0 15px;

    a {
      color: #3498db;
    }
  }

  .content {
    padding: 0 15px;
    a {
      color: #3498db;
      font-weight: 600;
      text-decoration: none;
      &:hover {
        color: #464445cf;
        text-decoration: underline;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .container {
    padding: 0;
  }
  .statistic {
    margin: 0;
  }
}
</style>

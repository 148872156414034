import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import { createHead } from "@vueuse/head";

// const head = createHead();

const app = createApp({
  extends: App,
  beforeCreate() {
    // this.$store.dispatch("auth/initialiseStore");
  },
});

app.config.globalProperties.$axios = axios;

app.use(store);
app.use(router);
// app.use(head);

app.mount("#app");

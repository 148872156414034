<template>
  <div class="main">
    <!-- <vue-headful :title="title" /> -->
    <navbar />
    <div class="container">
      <div class="routes p-3">
        <p class="breadcrumbs no-mobile">
          <span
            ><router-link :to="{ name: 'home' }">Start</router-link> /
            <router-link :to="{ name: 'statistik' }">Statistik</router-link>
            /</span
          >
          Sträcka
        </p>
        <div class="input-fields">
          <div class="mb-3">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Från"
                v-model="from"
                @keyup.13.native="update"
              />
              <input
                type="text"
                class="form-control"
                placeholder="Till"
                v-model="to"
                @keyup.13.native="update"
              />
              <div class="datepicker">
                <datepicker
                  :locale="lang"
                  class="form-control"
                  :style="style"
                  v-model="datum"
                  placeholder="Välj datum"
                  @update:modelValue="selectedDate"
                ></datepicker>
              </div>
              <button type="button" class="btn btn-info" @click="update">
                Sök
              </button>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center my-4" v-if="loading">
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <trains-per-route
          :trainlist="statlist"
          :datum="getDatum()"
          v-if="statlist.length > 0 && !loading"
        />

        <div
          class="alert alert-secondary mx-3"
          role="alert"
          v-if="statlist.length == 0 && !loading"
        >
          Inga sträckor hittades
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import TrainsPerRoute from "@/components/TrainsPerRoute";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import { sv } from "date-fns/locale";
import { mapState } from "vuex";

export default {
  name: "route",
  props: ["date", "fromStation", "toStation"],
  data() {
    return {
      lang: sv,
      statlist: [],
      delayLimit: 5,
      rating: 0,
      from: "",
      to: "",
      title: "Tågstatistik",
      datum: null,
      loading: false,
      style: {
        borderRadius: 0,
        height: "45px",
        minWidth: "250px",
      },
    };
  },

  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },

  components: {
    TrainsPerRoute,
    Datepicker,
    Navbar,
  },

  mounted() {
    this.from = this.fromStation;
    this.to = this.toStation;
    if (this.from && this.from && this.to) {
      this.update();
    }
  },

  methods: {
    getDatum() {
      return this.datum
        ? this.datum
        : moment().subtract(1, "day").format("YYYY-MM-DD");
    },

    update() {
      if (this.from && this.to) {
        this.loading = true;
        this.updateTitle();
        var d;
        if (this.datum == null) {
          d = moment().subtract(1, "day");
          this.$router.push({
            name: "routeWithoutDate",
            params: { fromStation: this.from, toStation: this.to },
          });
        } else {
          d = this.datum;
          this.$router.push({
            name: "routeWithQuery",
            params: {
              fromStation: this.from,
              toStation: this.to,
              date: moment(this.datum).format("YYYY-MM-DD"),
            },
          });
        }
        this.$axios
          .get(
            this.baseUrl +
              "api/ts_stracka.php?datum=" +
              moment(d).format("YYYY-MM-DD") +
              "&from=" +
              encodeURIComponent(this.from) +
              "&to=" +
              encodeURIComponent(this.to)
          )
          .then((response) => {
            this.loading = false;
            this.statlist = response.data;
          });
      }
    },

    updateTitle() {
      this.title = "Punktlighet " + this.from + " - " + this.to;
    },

    selectedDate(date) {
      this.datum = date;
      this.update();
    },
  },
};
</script>

<style lang="scss" scoped>
.routes {
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 10px;

  .breadcrumbs {
    font-weight: 600 !important;
    text-decoration: none;
    font-size: 0.95rem;
    padding: 0 15px;

    a {
      color: #3498db;
    }
  }
}

.input-fields {
  border-radius: 4px;
  .input-group {
    input {
      height: 45px;
      border-radius: 0;
      text-align: left;
      padding-left: 15px;
      margin: 0;
      border: 1px solid #ddd;
    }
    input.to {
      border-right: 0;
    }
    .btn-info {
      width: 150px;
      border-radius: 0;
      font-size: 18px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-color: transparent;
    }
  }
}

@media (max-width: 575.98px) {
  .no-mobile {
    display: none;
  }
  .operators {
    margin-top: -25px;
  }
  .h4 {
    margin-bottom: -8px;
  }

  .breadcrumbs {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .input-fields {
    padding-top: 0;
    padding-bottom: 0;
    .input-group {
      input,
      .btn-info {
        margin-top: 10px;
        width: 100%  !important;
      }
    }

    .datepicker {
      margin-top: 10px;
      width: 100%;
      input {
        margin-top: 10px;
      }
      .form-control {
        border-radius: 0 !important;
      }
    }
  }

  .info-text {
    font-size: 13px;
  }
  .container {
    margin-top: -25px;
    max-width: 98%;
    padding: 0px;
  }
  .table {
    .operator {
      padding: 0px;
    }
    thead th {
      font-size: 0.53rem;
      font-weight: 600;
      padding: 0px;
    }
    tbody {
      th {
        font-size: 10px;
      }
      .status .percent {
        font-size: 12px;
      }
      tr > td {
        padding: 1px;
      }
      tr > th {
        padding: 1px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .main {
    background-color: #fff;
  }
  .container {
    margin: 0 auto;
    max-width: 98%;
  }
  .table {
    margin-left: 1px;
  }
}

@media (max-width: 1199.98px) {
  .mob-smaller {
    font-size: 16px;
  }
  .table {
    th {
      font-size: 12px;
    }
    tr {
      font-size: 12px;
    }
  }
}
</style>

<template>
  <footer class="footer">
    <div class="content">
      <p>
        Made with <i class="bi bi-heart-fill"></i> for the railway by
        <a href="https://www.tydal.se/">
          <img :src="require('@/assets/tydal.png')" alt="Tydal systems" />
        </a>
      </p>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background: #fff;
  .content {
    max-width: 400px;
    p {
      margin: 0;
      text-align: center;
      font-weight: 600;
      color: #828282;
      font-size: 0.9rem;
      .bi {
        color: #ff0000;
        font-size: 0.8rem;
      }
      a {
        img {
          width: 70px;
          margin-top: -12px;
        }
      }
    }
  }
}
</style>

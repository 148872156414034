const state = {
  visible: false,
};

const mutations = {
  TEGGOLE_MENU(state) {
    state.visible = !state.visible;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

<template>
  <nav class="navbar navbar-expand bg-light">
    <div class="container">
      <div class="d-flex justify-content-between w-100">
        <div class="nav-left">
          <router-link class="navbar-brand" :to="{ name: 'home' }">
            <span>Tågstatistik</span>
            <img
              src="@/assets/stat.png"
              alt="Logo Tågstatistik"
              class="d-inline-block align-text-top"
              height="40"
            />
          </router-link>
        </div>
        <div class="nav-right">
          <span class="bars" @click="toggle">
            <i class="bi bi-list"></i>
          </span>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link active" :to="{ name: 'home' }"
                >Start</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'statistik' }"
                >Information</router-link
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >Statistik</a
              >
              <ul class="dropdown-menu">
                <li>
                  <router-link class="dropdown-item" :to="{ name: 'train' }"
                    >Tågnummer</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" :to="{ name: 'route' }"
                    >Sträckor</router-link
                  >
                </li>
                <li>
                  <router-link class="dropdown-item" :to="{ name: 'tracks' }"
                    >Spår</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    toggle() {
      this.$store.commit("menu/TEGGOLE_MENU");
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid #ddd;
  .nav-left {
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      margin-left: 15px;
      font-weight: 600;
      color: #fff;
      font-size: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .navbar-brand {
      span {
        color: #707b7c;
        font-size: 1.25rem;
        font-weight: 600;
      }
      img {
        margin-top: -5px;
        margin-left: 5px;
      }
    }
  }

  .nav-right {
    position: relative;
    display: flex;
    flex-direction: row;
    .bars {
      display: none;
      .bi {
        color: #3498db;
        font-size: 30px;
        margin-top: 2px;
      }
    }
    .navbar-nav {
      .nav-item {
        a {
          color: #3498db;
          font-size: 0.95rem;
          font-weight: 600;
        }
        .dropdown-menu {
          margin-top: 8px;
          min-width: 220px;
          border-radius: 0;
          border-top-color: #e2e2e2;
          background: whitesmoke;
          li {
            &:not(:last-child) {
              border-bottom: 1px solid #ddd;
            }
            .dropdown-item {
              padding: 12px 15px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .navbar {
    padding: 12px 8px !important;
    .nav-right {
      .bars {
        display: block;
      }
      .navbar-nav {
        display: none;
      }
    }
  }
}
</style>

<template>
  <div>
    <!-- <vue-headful title="Tågstatistik" /> -->
    <navbar />
    <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="welcome p-4">
              <h1>Välkommen till Tågstatistik!</h1>
              <p>
                Här får du information om tågtrafiken i Sverige. Vi har
                statistik om punktlighet och om förseningsorsaker. Undrar du
                något så
                <a
                  href="https://xn--frga-roa.xn--tgexperterna-tcb.nu/stall-en-fraga/"
                  >fråga oss</a
                >.
              </p>
              <p>
                Sök på ett
                <router-link :to="{ name: 'train' }">tågnummer</router-link> och
                se punktligheten dag för dag, eller sök på en
                <router-link :to="{ name: 'route' }">sträcka</router-link> och
                få upp alla tåg som gått där en viss dag. Här på startsidan kan
                du se statistik per tågoperatör och per förseningsorsak. Eller
                varför inte titta på vilket
                <router-link :to="{ name: 'tracks' }">spår</router-link> tåget
                brukar gå från?
              </p>
              <p>
                Vi förädlar datat från Trafikverket, men vi gör det automatiskt
                till skillnad från den officiella statistiken där man går igenom
                datat manuellt och rättar eventuella kvalitetsbrister.
              </p>
              <p>
                Vi gör detta för att vi vill få tågtrafiken att fungera bättre
                och att fler ska vilja åka tåg. Funderar du på att börja pendla
                med tåg? Kolla statistiken här! På vissa sträckor är tågen redan
                punktliga, på andra finns det utrymme för förbättringar - och vi
                hoppas att statistiken kan hjälpa till att åstadkomma
                förändring.
              </p>
              <p>
                Är det någon annan statistik du skulle vilja se? Åsikter eller
                andra önskemål? Hör av dig till synpunkter at tydalsystems.se.
              </p>
              <p><b>OBS!</b> Tidigare har antalet inställda tåg visat totalt
              antal inställda tåg under perioden 2022-12-11 -- 2023-07-13. Även antalet
            tåg (och följaktligen förseningsprocenten) har inkluderat det totala antalet
          tåg. Definitionen för punktlighet är dock att planerat inställda tåg inte ska tas med och så har det också varit fram till 2022-12-11. Bytet av planeringssystem hos Trafikverket ledde till att detta data började skickas på ett annat sätt och vi har först nu (2023-07-14) korrigerat för detta, med undantag av perioden 2022-12-11 -- 2022-12-16 som vi inte lyckades återskapa.</p>
              <p>
                Vill du lära dig mer om tåg och järnvägar? Titta in på
                <a href="https://xn--frga-roa.xn--tgexperterna-tcb.nu"
                  >fråga.tågexperterna.nu</a
                >.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="punktlighet p-4">
              <h3 class="mob-smaller">Punktlighet fram till i går</h3>
              <template v-if="!loading">
                <div class="spinner">
                  <pulse-loader :size="size"></pulse-loader>
                  <p class="info">Hämtar statistik...</p>
                </div>
              </template>
              <div class="chart-wrapper" v-if="loading">
                <line-chart
                  :width="1100"
                  :height="200"
                  :labels="labels"
                  :datasets="datasets"
                  :options="options"
                  :key="updateKey"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <StartTrains />
          </div>
          <div class="col">
            <StartDelays />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import StartTrains from "@/components/StartTrains";
import StartDelays from "@/components/StartDelays";
import LineChart from "@/components/charts/LineChart.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "home",
  data() {
    return {
      loading: false,
      stats: [],
      size: "18px",
      labels: [],
      datasets: [
        {
          label: "Punktlighet vid slutstation, hela Sverige",
          data: [],
          fill: false,
          backgroundColor: "#f87979",
          borderColor: "#f87979",
          tension: 0.1,
          pointHoverBorderWidth: 5,
          pointRadius: 0,
        },
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            autoSkip: false,
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          },
          y: {
            autoSkip: false,
            type: "linear",
            ticks: {
              beginAtZero: false,
              stepSize: 5,
              suggestedMin: 60,
              suggestedMax: 100,
              callback: function (value) {
                return value + " %";
              },
            },
          },
        },
      },
      updateKey: 0,
    };
  },

  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },

  components: {
    Navbar,
    PulseLoader,
    StartTrains,
    StartDelays,
    LineChart,
  },

  mounted() {
    moment.locale("sv");
    this.fetchTrainOperatorsData();
  },

  methods: {
    fetchTrainOperatorsData() {
      this.loading = false;
      this.$axios.get(this.baseUrl + "api/ts_sverige.php").then((res) => {
        this.stats = res.data;
        this.loading = true;
        this.createStats();
        this.updateKey++;
      });
    },

    createStats() {
      this.labels = this.stats.map((stat) =>
        moment(stat.datum).format("MMM D")
      );
      this.datasets[0].data = this.stats.map((stat) => stat.punktlighet);
    },
  },
};
</script>

<style lang="scss">
.welcome,
.punktlighet {
  margin-top: 15px;
  background: #fff;
}
.input-fields {
  padding: 20px 15px;
  .input-group {
    a {
      margin-top: 8px;
    }
  }
}
.info-heading {
  margin-top: 15px;
  font-weight: bold;
  font-size: 14px;
}
.arrows {
  max-height: 20px;
  margin-top: 2px;
}
.btn-info {
  font-weight: bold;
  background-color: #6b93bb;
  border-color: #587592;
}
.btn-info:hover {
  background-color: #5e80a3;
  border-color: #587592;
}
@media (max-width: 575.98px) {
  .top {
    margin: 0;
  }
  .cbox {
    margin: 0;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 1199.98px) {
  .mob-smaller {
    font-size: 16px;
  }
  .table {
    th {
      font-size: 12px;
    }
  }
}
</style>

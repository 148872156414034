<template>
  <div id="app">
    <router-view />
    <v-footer />

    <div class="sidebar-mobile" :class="{ open: visible }">
      <div class="d-flex justify-content-end">
        <span class="close" @click="closeMenu()">
          <i class="bi bi-x"></i>
        </span>
      </div>
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item" @click="navigate('home')">
          <router-link class="nav-link" to="">Start</router-link>
        </li>
        <li class="nav-item" @click="navigate('statistik')">
          <router-link class="nav-link" to="">Information</router-link>
        </li>
        <li class="nav-item">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Statistik</a
          >
          <ul class="dropdown-menu">
            <li>
              <button class="dropdown-item" @click="navigate('train')">
                Tågnummer
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="navigate('route')">
                Sträckor
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="navigate('tracks')">
                Spår
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      visible: (state) => state.menu.visible,
    }),
  },

  components: {
    "v-footer": Footer,
  },

  methods: {
    closeMenu() {
      this.$store.commit("menu/TEGGOLE_MENU");
    },

    navigate(route) {
      this.$router.push({ name: route });
      this.closeMenu();
    },
  },
};
</script>

<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
html,
body {
  height: 100%;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #707070;
  background: #f3f3f3;

  .sidebar-mobile {
    background: #2980b9;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    padding: 2em;
    position: fixed;
    top: 0;
    left: -110%;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    text-align: center;

    .close {
      cursor: pointer;

      .bi {
        color: #fff;
        font-size: 35px;
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      width: 300px;
      margin: 0 auto;
      padding-top: 25px;

      li {
        padding: 7px 10px;
        background: #bdc3c7;
        margin-bottom: 20px;
        border-radius: 5px;

        a {
          color: #fff;
          text-decoration: none;
          font-size: 1rem;
          font-weight: 600;
        }
        .dropdown-menu {
          width: 280px;
          padding: 20px 15px;
          li {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .sidebar-mobile.open {
    left: 0;

    .mob-menu-grey {
      background-color: #525151;
    }
  }

  @media (max-width: 767.98px) {
    .sidebar {
      display: none;
    }
  }

  @media (min-width: 768px) {
    .sidebar-mobile.open {
      left: -100%;
    }
  }
}
.main {
  min-height: calc(100vh - 120px);
}
.breadcrumbs {
  margin: 20px 0;
  span {
    color: #808080;
  }
}
.chart-wrapper {
  background: #ffffff;
  margin: 20px 0;
  padding: 10px 15px 10px 10px;
}
.spinner {
  text-align: center;
  margin: 25px 0;
  background: #f1f1f1;
  padding: 20px 0;
  border-radius: 4px;
  .info {
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    font-size: 15px;
    margin: 0;
  }
}
</style>

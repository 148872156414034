<template>
  <div class="main">
    <!-- <vue-headful :title="title" /> -->
    <navbar />
    <div class="container">
      <div class="tracks p-3">
        <p class="breadcrumbs no-mobile">
          <span><router-link :to="{ name: 'home' }">Start</router-link> /</span>
          Spår
        </p>
        <div class="input-fields">
          <div class="mb-3">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Station"
                v-model="tpl"
                @keyup.13.native="update"
              />
              <input
                type="text"
                class="form-control"
                placeholder="Tågnummer"
                v-model="atagNr"
                @keyup.13.native="update"
              />
              <button type="button" class="btn btn-info" @click="update">
                Sök
              </button>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center my-4" v-if="loading">
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <tracks-per-train
          :trainlist="statlist"
          :tagNr="atagNr"
          v-if="statlist.length > 0 && !loading"
        />

        <div
          class="alert alert-secondary mx-3"
          role="alert"
          v-if="statlist.length == 0 && !loading"
        >
          Ange station och tågnummer för att söka
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import TracksPerTrain from "@/components/TracksPerTrain";
import { mapState } from "vuex";

export default {
  name: "tracks",
  props: ["tagNr", "station"],
  data() {
    return {
      statlist: [],
      atagNr: null,
      tpl: null,
      title: "Spårstatistik",
      loading: false,
    };
  },

  computed: {
    ...mapState({
      baseUrl: (state) => state.baseUrl,
    }),
  },

  components: {
    Navbar,
    TracksPerTrain,
  },

  mounted() {
    let query = this.$route.query;
    if (query.station && query.tagNr) {
      this.tpl = query.station;
      this.atagNr = query.tagNr;
      this.update();
    }
  },

  methods: {
    updateTitle() {
      this.title = "Spår i " + this.tpl + " för tåg " + this.atagNr;
    },

    update() {
      if (this.tpl && this.atagNr) {
        this.loading = true;
        this.updateTitle();
        this.$router.replace({
          query: { station: this.tpl, tagNr: this.atagNr },
        });
        this.$axios
          .get(
            this.baseUrl +
              "api/ts_spar.php?atagNr=" +
              this.atagNr +
              "&tpl=" +
              encodeURIComponent(this.tpl)
          )
          .then((response) => {
            this.loading = false;
            this.statlist = response.data;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tracks {
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 10px;
  .breadcrumbs {
    font-weight: 600 !important;
    text-decoration: none;
    font-size: 0.95rem;
    padding: 0 15px;

    a {
      color: #3498db;
    }
  }
}
.input-fields {
  border-radius: 4px;
  .input-group {
    input {
      height: 45px;
      width: 250px;
      border-radius: 0;
      text-align: left;
      padding-left: 15px;
      font-size: 17px;
      margin: 0;
      border: 1px solid #ddd;
    }
    input.to {
      border-right: 0;
    }
    .btn-info {
      width: 150px;
      border-radius: 0;
      font-size: 18px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-color: transparent;
    }
  }
}
@media (max-width: 575.98px) {
  .no-mobile {
    display: none;
  }
  .operators {
    margin-top: -25px;
  }
  .h4 {
    margin-bottom: -8px;
  }

  .breadcrumbs {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .info-text {
    font-size: 13px;
  }
  .container {
    padding: 0px;
  }
  .input-fields {
    margin: 0;
    padding-top: 0;

    .input-group {
      input,
      .btn-info {
        margin-top: 10px;
        width: 100%;
        border-radius: 0;
      }
    }
  }
  .table {
    .operator {
      padding: 0px;
    }
    thead th {
      font-size: 0.53rem;
      font-weight: 600;
      padding: 0px;
    }
    tbody {
      th {
        font-size: 10px;
      }
      .status .percent {
        font-size: 12px;
      }
      tr > td {
        padding: 10px;
      }
      tr > th {
        padding: 1px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .main {
    background-color: #fff;
  }
  .container {
    margin: 0 auto;
    max-width: 98%;
  }
  .table {
    margin-left: 1px;
  }
}

@media (max-width: 1199.98px) {
  .mob-smaller {
    font-size: 16px;
  }
  .table {
    th {
      font-size: 12px;
    }
    tr {
      font-size: 12px;
    }
  }
}
</style>
